import { useTranslation } from 'react-i18next'
import { useKnaga } from '../../hooks/useKnaga'
import { TranslationKeys } from '../../i18n'
import { UserState, useCurrentUser } from '../../user/CurrentUserProvider'
import { pokeKnaga } from '../../pokeKnaga'
import { KnagaCanvas } from './KnagaCanvas'

export interface KnagaProps {
  uid?: string
}

interface PokesInfoProps {
  viewingSelf: boolean
  hoursToReset: number
  pokes: number
  selfPokes: number
}

function PokesInfo(props: PokesInfoProps) {
  const translation = useTranslation()
  const { viewingSelf, hoursToReset, pokes, selfPokes } = props

  const segments = []

  if (viewingSelf) {
    segments.push(translation.t(TranslationKeys.selfPokesLeft, { count: selfPokes }))

    if (selfPokes === 0) {
      segments.push(translation.t(TranslationKeys.comeBackInHours, { count: hoursToReset }))
    }
  } else {
    segments.push(translation.t(TranslationKeys.pokesLeft, { count: pokes }))

    if (pokes === 0) {
      segments.push(translation.t(TranslationKeys.comeBackInHours, { count: hoursToReset }))
    }
  }

  return <div style={{ textAlign: 'center' }}>{segments.join(' ')}</div>
}

export function Knaga(props: KnagaProps) {
  const translation = useTranslation()
  const user = useCurrentUser()
  const knaga = useKnaga(props.uid)

  if (!knaga) {
    return null
  }

  const viewingSelf = props.uid === user.uid
  const hoursToReset = 24 - Math.floor((Date.now() - user.pokesResetTimestamp) / 1000 / 60 / 60)

  return (
    <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
      <span style={{ textAlign: 'center', marginBottom: 16 }}>
        {translation.t(TranslationKeys.knagaSize, { size: knaga?.size })}
      </span>

      <KnagaCanvas knaga={knaga} />

      {user.state === UserState.SIGNED_IN && (
        <div style={{ textAlign: 'center' }}>
          <button
            disabled={viewingSelf ? user.selfPokes <= 0 : user.pokes <= 0}
            onClick={() => {
              pokeKnaga(user.uid, props.uid)
            }}
          >
            {translation.t(TranslationKeys.pokeKnaga)}
          </button>
          <PokesInfo
            viewingSelf={viewingSelf}
            hoursToReset={hoursToReset}
            pokes={user.pokes}
            selfPokes={user.selfPokes}
          />
        </div>
      )}
    </div>
  )
}
