import { KnagaInfo } from "../../hooks/useKnaga";
import { KNAGA_BOTTOM_MARGIN, canShowTip, getClampedKnagaHeight, getTipSize } from "./sizing";
import { Animation, Dimensions } from "./types";

export function drawKnaga(
  context: CanvasRenderingContext2D,
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
  const scale = window.devicePixelRatio
  const clampedSize = getClampedKnagaHeight(canvasSize, animation, knaga)
  const tipSize = getTipSize(canvasSize, animation, knaga)
  const tipVisible = canShowTip(canvasSize, animation, knaga)

  const radii = tipVisible ? [0, 0, knaga.girth / 2 * scale, knaga.girth / 2 * scale] : knaga.girth / 2 * scale
  const size = tipVisible ? clampedSize - tipSize : clampedSize

  context.beginPath()
  context.roundRect(
    (canvasSize.width - knaga.girth) / 2 * scale,
    (canvasSize.height - size - KNAGA_BOTTOM_MARGIN - 1) * scale,
    knaga.girth * scale,
    size * scale,
    radii,
  )
  context.fill()
}