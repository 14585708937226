import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { logEvent } from '@firebase/analytics';
import { analytics } from '../firebase';
import { AuthState, useAuth } from '../auth/AuthInfoProvider';

export function useAnalyticsEvent(additionalInfo: Record<string, unknown> = {}, dataReady = true) {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (auth.state !== AuthState.UNKNOWN && dataReady) {
      logEvent(analytics, 'screen_view', {
          ...additionalInfo,
          firebase_screen: location.pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
          firebase_screen_class: 'firebase-routes-analytics', // <- This name is up to you
          signed_in: auth.state === AuthState.SIGNED_IN,
      });
    }
  }, [location, auth.state, dataReady]);
}
