export class Cache {
  private static getDefaultTTL(path: string) {
    if (path.startsWith('uid2name') || path.startsWith('name2uid')) {
      return 1000 * 60 * 60 * 24 * 30 // 30 days, usernames are immutable
    }

    if (path.startsWith('users')) {
      return 1000 * 60 * 5 // 5 minutes for poke counters
    }

    if (path.match(/seasons\/.*\/rankings\/size/)) {
      return 1000 * 60 * 75 // size ranking is regenerated every 2 hours, this should work kinda ok
    }

    return 1000 * 60 // 1 minute
  }

  static set<T = any>(path: string, data: T, ttl?: number) {
    if (ttl === undefined) {
      ttl = this.getDefaultTTL(path)
    }

    const item = {
      expires: Date.now() + ttl,
      value: data
    }
    // console.log(path, 'cache set, ttl:', ttl)

    localStorage.setItem(path, JSON.stringify(item))
  }

  static invalidate(path: string) {
    // console.log(path, 'cache cleared')
    localStorage.removeItem(path)
  }

  static get<T = any>(path: string, allowStale = false): T | undefined {
    const cached = localStorage.getItem(path)

    if (cached === null) {
      // console.log(path, 'cache miss')
      return undefined
    }

    const item = JSON.parse(cached)

    if (Date.now() > item.expires && !allowStale) {
      // console.log(path, 'cache expired')
      this.invalidate(path)
      return undefined
    }

    // console.log(path, 'cache hit')
    return item.value
  }

  static update<T = any>(path: string, data: T) {
    const cached = localStorage.getItem(path)

    if (cached === null) {
      // console.log(path, 'cache update miss')
      return
    }

    const item = JSON.parse(cached)
    item.value = data

    // console.log(path, 'cache update, ttl:', item.expires - Date.now())

    localStorage.setItem(path, JSON.stringify(item))
  }

  static clear() {
    localStorage.clear()
  }
}