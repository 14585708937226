import { httpsCallable } from "firebase/functions"
import { logEvent } from "firebase/analytics"
import { functions, analytics } from "./firebase"
import { Database } from "./database/database"
import { getCurrentKnagaPath } from 'functions/lib/common'

const FLUSH_INTERVAL = 700
const remotePokeKnaga = httpsCallable(functions, 'pokeKnaga')

let pokeCounter: Record<string, number> = {}
let timeoutRef: ReturnType<typeof setTimeout> | undefined = undefined

function scheduleFlush(userUid: string) {
  if (timeoutRef) {
    clearTimeout(timeoutRef)
  }
  timeoutRef = setTimeout(() => {
    flushPokes(userUid)
  }, FLUSH_INTERVAL)
}

function flushPokes(userUid: string) {
  for (const [uid, count] of Object.entries(pokeCounter)) {
    remotePokeKnaga({ targetUid: uid, count: count })
      .then(() => {
        console.log('remote poke for', uid, 'batched:', count, 'succeeded')
      })
      .catch((e) => {
        console.error('remote poke for', uid, 'batched:', count, 'failed', e, 'reverting local')

        if (userUid === uid) {
          Database.update(getCurrentKnagaPath(uid) , {
            size: (size: number) => size - count
          })
          console.log(JSON.stringify(e))
          if (e.code !== 'functions/resource-exhausted') {
            Database.update('users/' + uid, {
              selfPokes: (selfPokes: number) => selfPokes + count
            })
          }
        } else {
          Database.update(getCurrentKnagaPath(userUid) , {
            size: (size: number) => size - count
          })
      
          Database.update(getCurrentKnagaPath(uid) , {
            size: (size: number) => size - count * 2
          })

          if (e.code !== 'functions/resource-exhausted') {
            Database.update('users/' + uid, {
              pokes: (pokes: number) => pokes + count
            })
          }
        }
      })
  }

  pokeCounter = {}
}

export function pokeKnaga(userUid?: string, targetUid?: string) {
  if (!userUid || !targetUid) {
    return
  }

  if (userUid === targetUid) {
    scheduleFlush(userUid)
    pokeCounter[targetUid] = (pokeCounter[targetUid] || 0) + 1

    Database.update('users/' + userUid, {
      selfPokes: (count: number) => {
        if (count === 0) {
          throw new Error("Self pokes exhausted")
        }
        return count - 1
      }
    })

    Database.update(getCurrentKnagaPath(targetUid) , {
      size: (size: number) => size + 1
    })
  } else {
    scheduleFlush(userUid)
    pokeCounter[targetUid] = (pokeCounter[targetUid] || 0) + 1

    Database.update('users/' + userUid, {
      pokes: (count: number) => {
        if (count === 0) {
          throw new Error("Pokes exhausted")
        }
        return count - 1
      }
    })

    Database.update(getCurrentKnagaPath(userUid) , {
      size: (size: number) => size + 1
    })

    Database.update(getCurrentKnagaPath(targetUid) , {
      size: (size: number) => size + 2
    })
  }

  logEvent(analytics, 'poke', { user: userUid, target: targetUid, selfPoke: userUid === targetUid })
}