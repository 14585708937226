import './style.css'

interface ActivityIndicatorProps {
  visible?: boolean
}

export default function ActivityIndicator(props: ActivityIndicatorProps) {
  const { visible = true } = props

  return (
    <div className="activity-indicator-size">
      {visible && (
        <>
          <div className="activity-indicator" />
          <div className="activity-indicator" />
        </>
      )}
    </div>
  )
}
