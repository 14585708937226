import React, { useState, useCallback, useEffect } from 'react'
import { Modal } from '../../components/Modal'
import { useTranslation } from 'react-i18next'
import { TranslationKeys } from '../../i18n'
import { UserState, useCurrentUser } from '../../user/CurrentUserProvider'

const shouldShow = localStorage.getItem('tipsModalShown') !== 'true'

export function TipsModal() {
  const translation = useTranslation()
  const user = useCurrentUser()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (shouldShow && user.state === UserState.SIGNED_IN) {
      setVisible(shouldShow)
    }
  }, [user.state])

  const closeModal = useCallback(() => {
    setVisible(false)
    localStorage.setItem('tipsModalShown', 'true')
  }, [])

  return (
    <Modal
      visible={visible}
      onClose={closeModal}
      title={translation.t(TranslationKeys.importantInformation)}
    >
      <span>{translation.t(TranslationKeys.easyStepsToEnlargeKnaga)}</span>
      <ol>
        <li>{translation.t(TranslationKeys.enlargeKnagaStep1)}</li>
        <li>{translation.t(TranslationKeys.enlargeKnagaStep2)}</li>
        <li>{translation.t(TranslationKeys.enlargeKnagaStep3)}</li>
      </ol>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <button onClick={closeModal}>{translation.t(TranslationKeys.ok)}</button>
      </div>
    </Modal>
  )
}
