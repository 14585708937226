import { KnagaInfo } from "../../hooks/useKnaga";
import { getFillStyle } from "./color";
import { KNAGA_BOTTOM_MARGIN, canShowTip, getClampedKnagaHeight, getTipSize } from "./sizing";
import { Animation, Dimensions } from "./types";

export function drawTip(
  context: CanvasRenderingContext2D,
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
  const scale = window.devicePixelRatio
  const knagaSize = getClampedKnagaHeight(canvasSize, animation, knaga)
  const tipSize = getTipSize(canvasSize, animation, knaga, false)

  if (!canShowTip(canvasSize, animation, knaga)) {
    return
  }

  if (knaga.tip === 1) {
    context.beginPath()
    context.arc(
      canvasSize.width / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale,
      tipSize * scale,
      Math.PI,
      2 * Math.PI
    )
    context.fill()
  } else if (knaga.tip === 2) {
    const horizontalMargin = 16

    context.beginPath()
    context.moveTo((canvasSize.width - knaga.girth) / 2 * scale, (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale)
    
    context.bezierCurveTo(
      (canvasSize.width - knaga.girth) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize / 10 + tipSize) * scale,
      (canvasSize.width - knaga.girth - horizontalMargin) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize / 10 + tipSize) * scale,
      (canvasSize.width - knaga.girth - horizontalMargin) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize / 5 + tipSize) * scale
    )

    context.bezierCurveTo(
      (canvasSize.width - knaga.girth - horizontalMargin) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize) * scale,
      canvasSize.width / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize) * scale,
      canvasSize.width / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize) * scale
    )

    context.bezierCurveTo(
      canvasSize.width / 2 * scale, 
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize) * scale,
      (canvasSize.width + knaga.girth + horizontalMargin) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize) * scale,
      (canvasSize.width + knaga.girth + horizontalMargin) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize / 5 + tipSize) * scale
    )

    context.bezierCurveTo(
      (canvasSize.width + knaga.girth + horizontalMargin) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize / 10 + tipSize) * scale,
      (canvasSize.width + knaga.girth) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize / 10 + tipSize) * scale,
      (canvasSize.width + knaga.girth) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale
    )

    context.fill()
  } else if (knaga.tip === 3) {
    const radius = knaga.girth / 2
    const cX = canvasSize.width / 2 * scale
    const cY = (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale
    const angle = Math.PI / 6

    context.beginPath()
    context.arc(
      cX,
      cY,
      radius * scale,
      Math.PI,
      2 * Math.PI
    )
    context.fill()

    context.lineWidth = 4 * scale

    context.beginPath()
    context.moveTo(cX + Math.cos(Math.PI * 1.5 - angle) * radius * scale, cY + (Math.sin(Math.PI * 1.5 - angle) * radius + 1) * scale)
    context.bezierCurveTo(
      cX - 20 * scale, 
      cY - tipSize * scale / 1.5, 
      cX - 50 * scale, 
      cY - tipSize * scale, 
      cX - 75 * scale,
      cY - tipSize * scale
    )
    context.stroke()

    context.beginPath()
    context.moveTo(cX + Math.cos(Math.PI * 1.5 + angle) * radius * scale, cY + (Math.sin(Math.PI * 1.5 + angle) * radius + 1) * scale)
    context.bezierCurveTo(
      cX + 20 * scale, 
      cY - tipSize * scale / 1.5, 
      cX + 50 * scale, 
      cY - tipSize * scale, 
      cX + 75 * scale,
      cY - tipSize * scale
    )
    context.stroke()
  } else if (knaga.tip === 4) {
    const eyeRadius = knaga.girth / 8

    context.beginPath()
    context.moveTo((canvasSize.width - knaga.girth) / 2 * scale, (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale)
    
    context.bezierCurveTo(
      (canvasSize.width - knaga.girth + 10) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.2) * scale,
      canvasSize.width / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.1) * scale,
      (canvasSize.width / 2 + 10) * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize) * scale
    )

    context.lineTo(
      (canvasSize.width / 2 + 15) * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.25) * scale
    )

    context.lineTo(
      (canvasSize.width / 2 + 30) * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.025) * scale
    )

    context.bezierCurveTo(
      (canvasSize.width / 2 + 35) * scale, 
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.2) * scale,
      (canvasSize.width + knaga.girth) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.5) * scale,
      (canvasSize.width + knaga.girth) / 2 * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale
    )

    context.fill()

    context.fillStyle = 'black'

    context.beginPath()
    context.arc(
      (canvasSize.width / 2 - eyeRadius / 4) * scale,
      (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - tipSize + tipSize * 1.275 + eyeRadius) * scale,
      eyeRadius * scale,
      0,
      2 * Math.PI
    )
    context.fill()

    context.fillStyle = getFillStyle(knaga)
  }
}