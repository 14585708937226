// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFunctions } from 'firebase/functions'
import { getFirestore } from 'firebase/firestore'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


const firebaseConfig = {
  apiKey: "AIzaSyAS6lkvw9TWgYud1SloHPLRAcpdTUbhesk",
  authDomain: "knaga.net",
  projectId: "knaga-net",
  storageBucket: "knaga-net.appspot.com",
  messagingSenderId: "399892550375",
  appId: "1:399892550375:web:b203281216d63e623a0842",
  measurementId: "G-DEFQLYBD38"
}

export const app = initializeApp(firebaseConfig)

// @ts-ignore I'm aware it doesn't exist on window, that's why I'm setting it
self.FIREBASE_APPCHECK_DEBUG_TOKEN = '9b6200fe-a9ed-4c04-a535-0d61cfc4b7d0';

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Le2D58mAAAAAPi9SNrVxb7Bg86WeiO7U5C-Z-Ne'),
  isTokenAutoRefreshEnabled: true
});

export const analytics = getAnalytics(app)
export const functions = getFunctions(app, 'europe-west1')
export const firestore = getFirestore(app);
