import { useState, useCallback } from 'react'
import { useCurrentUser } from '../../user/CurrentUserProvider'
import { setUserName } from '../../user/setUserName'
import ActivityIndicator from '../../components/ActivityIndicator'
import { useTranslation } from 'react-i18next'
import TopBar from '../../components/TopBar'
import { TranslationKeys } from '../../i18n'
import { useAnalyticsEvent } from '../../hooks/useAnalyticsEvent'

export default function SetUsernameScreen() {
  useAnalyticsEvent({ screen: 'set_username' })
  const translation = useTranslation()
  const user = useCurrentUser()
  const [name, setName] = useState('')
  const [waiting, setWaiting] = useState(false)
  const [message, setMessage] = useState(' ')

  const trySetUserName = useCallback(() => {
    setWaiting(true)
    setMessage(' ')

    setUserName(user.uid!, name)
      .then(() => {
        setWaiting(false)
      })
      .catch((e) => {
        setWaiting(false)
        setMessage(e.message)
      })
  }, [user.uid, name])

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TopBar />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <div style={{ padding: 32, display: 'flex', flexDirection: 'column', gap: 8 }}>
          <span style={{ textAlign: 'center', fontSize: '1.5em', margin: 8 }}>
            {translation.t(TranslationKeys.selectUsername)}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              placeholder={translation.t(TranslationKeys.usernamePlaceholder)}
              disabled={waiting}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  trySetUserName()
                }
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button disabled={waiting} onClick={trySetUserName}>
              {translation.t(TranslationKeys.ok)}
            </button>
          </div>
          <span style={{ textAlign: 'center', fontSize: '1.2em', margin: 8, color: '#c00000' }}>
            {message === ' ' ? ' ' : translation.t(message)}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ActivityIndicator visible={waiting} />
          </div>
        </div>
      </div>
    </div>
  )

  return null
}
