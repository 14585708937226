import './style.css'

export interface ModalProps {
  visible: boolean
  title?: string
  onClose?: () => void
  children?: React.ReactNode
}

export function Modal(props: ModalProps) {
  if (!props.visible) {
    return null
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">
            <div>{props.title ?? ''}</div>
          </div>
          <div>
            <div
              className="modal-close material-symbols-outlined"
              onClick={() => {
                props.onClose?.()
              }}
            >
              close
            </div>
          </div>
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </div>
  )
}
