import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'
import { Database } from '../database/database'
import { TranslationKeys } from '../i18n'

const googleProvider = new GoogleAuthProvider()

const auth = getAuth()
auth.useDeviceLanguage()

export enum AuthProvider {
  Google,
}

export function signIn(withProvider: AuthProvider = AuthProvider.Google) {
  if (getAuth().currentUser !== null) {
    // user is already logged in
    return
  }

  const isMobile = /Android|iPhone/i.test(navigator.userAgent)

  let provider

  switch (withProvider) {
    case AuthProvider.Google:
      provider = googleProvider
      break

    default:
      throw new Error(TranslationKeys.errorUnknownLoginProvider)
  }

  if (isMobile) {
    signInWithRedirect(auth, provider).catch((e) => {
      console.error("Couldn't login", e.message)
    })
  } else {
    signInWithPopup(auth, provider).catch((e) => {
      console.error("Couldn't login", e.message)
    })
  }
}

export function signOut() {
  getAuth().signOut()
  Database.clear()
}
