import { KnagaInfo } from "../../hooks/useKnaga";

function colorToRGB(color: string) {
  const r = parseInt(color.substring(1, 3), 16)
  const g = parseInt(color.substring(3, 5), 16)
  const b = parseInt(color.substring(5, 7), 16)

  return { r, g, b }
}

export function getFillStyle(knaga: KnagaInfo) {
  return knaga.fill[0]
}

export function getGradientColor(knaga: KnagaInfo) {
  const color = knaga.fill[0]
  const { r, g, b } = colorToRGB(color)

  return { r: 255 - r, g: 255 - g, b: 255 - b }
}

export function isDark(color: string): boolean {
  const { r, g, b } = colorToRGB(color)

  return (0.299 * r + 0.587 * g + 0.114 * b) / 255 < 0.5
}