import { useEffect, useRef, useState } from 'react'
import { useSize } from '../../hooks/useSize'
import { KnagaInfo } from '../../hooks/useKnaga'
import { Animation } from './types'
import { drawBalls } from './drawBalls'
import { drawKnaga } from './drawKnaga'
import { drawTip } from './drawTip'
import { drawDecoration } from './drawDecoration'
import { getFillStyle, getGradientColor } from './color'

interface KnagaCanvasProps {
  knaga: KnagaInfo
}

export function KnagaCanvas(props: KnagaCanvasProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const animation = useRef<Animation>({
    lastFrameTime: 0,
    progress: 0,
  })
  const canvasSize = useSize(containerRef)
  const [rafHandle, setRafHandle] = useState<number | null>(null)

  const { knaga } = props

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')

    if (context) {
      if (rafHandle !== null) {
        cancelAnimationFrame(rafHandle)
      }

      const renderFunction = (time: number) => {
        const frameTime = Math.min(time - animation.current.lastFrameTime, 33)
        animation.current.lastFrameTime = time

        if (frameTime) {
          animation.current.progress += ((1 - animation.current.progress) * 0.05 * frameTime) / 16

          context.fillStyle = 'black'
          context.fillRect(
            0,
            0,
            canvasSize.width * window.devicePixelRatio,
            canvasSize.height * window.devicePixelRatio
          )

          const gradientColor = getGradientColor(knaga)
          const gradient = context.createRadialGradient(
            (canvasSize.width * window.devicePixelRatio) / 2,
            (canvasSize.height * window.devicePixelRatio) / 2,
            0,
            (canvasSize.width * window.devicePixelRatio) / 2,
            (canvasSize.height * window.devicePixelRatio) / 2,
            Math.max(
              (canvasSize.width * window.devicePixelRatio) / 2,
              (canvasSize.height * window.devicePixelRatio) / 2
            )
          )
          gradient.addColorStop(
            0,
            `rgba(${gradientColor.r}, ${gradientColor.g}, ${gradientColor.b}, 0.25)`
          )
          gradient.addColorStop(
            0.9,
            `rgba(${gradientColor.r}, ${gradientColor.g}, ${gradientColor.b}, 0.1)`
          )
          gradient.addColorStop(
            0.99,
            `rgba(${gradientColor.r}, ${gradientColor.g}, ${gradientColor.b}, 0.05)`
          )
          gradient.addColorStop(
            1,
            `rgba(${gradientColor.r}, ${gradientColor.g}, ${gradientColor.b}, 0.0)`
          )

          context.fillStyle = gradient
          context.fillRect(
            0,
            0,
            canvasSize.width * window.devicePixelRatio,
            canvasSize.height * window.devicePixelRatio
          )

          context.fillStyle = getFillStyle(knaga)
          context.strokeStyle = getFillStyle(knaga)

          drawBalls(context, canvasSize, animation.current, knaga)
          drawKnaga(context, canvasSize, animation.current, knaga)
          drawTip(context, canvasSize, animation.current, knaga)
          drawDecoration(context, canvasSize, animation.current, knaga)

          setRafHandle(requestAnimationFrame(renderFunction))
        }
      }

      setRafHandle(requestAnimationFrame(renderFunction))
    }
  }, [canvasSize, props.knaga])

  return (
    <div
      ref={(ref) => {
        if (!ref) {
          return
        }
        containerRef.current = ref
      }}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <canvas
        ref={(canvas) => {
          canvasRef.current = canvas
        }}
        style={{ width: canvasSize.width, height: canvasSize.height - 16 }}
        width={canvasSize.width * window.devicePixelRatio}
        height={canvasSize.height * window.devicePixelRatio}
      />
    </div>
  )
}
