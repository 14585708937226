import { KnagaInfo } from "../../hooks/useKnaga";
import { Animation, Dimensions } from "./types";

export const KNAGA_BOTTOM_MARGIN = 24

export function getClampedKnagaHeight(
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
    return Math.min(knaga.size, canvasSize.height - KNAGA_BOTTOM_MARGIN) * animation.progress
}

export function getTipSize(
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo,
  zeroIfKnagaTooSmall = true
) {
  if (knaga.tip === 1) {
    if (zeroIfKnagaTooSmall && knaga.size < knaga.girth / 2) {
      return 0
    }
    return knaga.girth / 2
  } else if (knaga.tip === 2) {
    if (zeroIfKnagaTooSmall && knaga.size < 100 + knaga.girth / 2) {
      return 0
    }
    return 100
  } else if (knaga.tip === 3) {
    if (zeroIfKnagaTooSmall && knaga.size < knaga.girth * 1.5) {
      return 0
    }
    return knaga.girth * 1.5
  } else if (knaga.tip === 4) {
    if (zeroIfKnagaTooSmall && knaga.size < 100 + knaga.girth / 2) {
      return 0
    }
    return 100
  }

  return 0
}

export function canShowTip(
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
  const knagaSize = getClampedKnagaHeight(canvasSize, animation, knaga)
  const tipSize = getTipSize(canvasSize, animation, knaga, false)

  return knagaSize > tipSize && knagaSize - tipSize > knaga.girth / 2
}

export function getBallsSize(
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
  if (knaga.balls === 1) {
    return 80
  } else if (knaga.balls === 2) {
    return Math.min(180, getClampedKnagaHeight(canvasSize, animation, knaga) - getTipSize(canvasSize, animation, knaga) + KNAGA_BOTTOM_MARGIN)
  }

  return 0
}
