import React, { useState, useEffect, useContext, useMemo } from 'react'
import { AuthState as UserState, useAuth } from '../auth/AuthInfoProvider'
import { registerNameChangeListener } from './setUserName'
import { Database } from '../database/database'
import { Consts } from 'functions/lib/common'

export { AuthState as UserState } from '../auth/AuthInfoProvider'

interface RemoteUserInfo {
  name: string | undefined
  selfPokes: number
  pokes: number
  pokesResetTimestamp: number
}

export interface UserStateInfo {
  state: UserState
  uid: string | undefined
}

export interface UserInfo extends RemoteUserInfo, UserStateInfo {}

const CurrentUserContext = React.createContext<UserInfo>({
  state: UserState.UNKNOWN,
  name: undefined,
  uid: undefined,
  selfPokes: 0,
  pokes: 0,
  pokesResetTimestamp: Date.now(),
})

const CurrentUserStateContext = React.createContext<UserStateInfo>({
  state: UserState.UNKNOWN,
  uid: undefined,
})

function parseRemoteUser(data: any): RemoteUserInfo {
  if (Date.now() - data.pokesReset.seconds * 1000 >= 1000 * 60 * 60 * 24) {
    data.selfPokes = Consts.DefaultSelfPokes
    data.pokes = Consts.DefaultPokes
  }

  return {
    pokes: data.pokes,
    selfPokes: data.selfPokes,
    name: data.name,
    pokesResetTimestamp: data.pokesReset.seconds * 1000,
  }
}

async function fetchUserInfo(uid: string): Promise<RemoteUserInfo> {
  await Database.fetch('uid2name', uid) // it will throw if the document doesn't exist, meaning the user didn't select name
  const data = await Database.fetch({ retryBackoff: 400 }, 'users', uid)

  return parseRemoteUser(data)
}

export function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth()
  const [userState, setUserState] = useState(UserState.UNKNOWN)
  const [remoteUser, setRemoteUser] = useState<RemoteUserInfo>({
    name: undefined,
    selfPokes: 0,
    pokes: 0,
    pokesResetTimestamp: Date.now(),
  })

  const state =
    // if the user is signed in but we haven't fetched the data yet, stall to prevent flickering
    userState === UserState.SIGNED_IN && remoteUser.name === undefined
      ? UserState.UNKNOWN
      : userState

  const stateInfo = useMemo(() => ({ uid: auth.user?.uid, state }), [state, auth.user?.uid])

  function fetchRemoteUser() {
    fetchUserInfo(auth.user!.uid)
      .then((result) => {
        setRemoteUser(result)

        // update the pokes in the database, they may have been reset
        Database.update('users/' + auth.user!.uid, {
          pokes: result.pokes,
          selfPokes: result.selfPokes,
        })
        // TODO: timeout for reseting the poke?
      })
      .catch(() => {
        // fetchUserInfo throws when the documents for uid <-> name mapping don't exist
        // otherwise it does exponential backoff
        setUserState(UserState.UNNAMED)
      })
  }

  useEffect(() => {
    if (userState !== UserState.UNNAMED || auth.state === UserState.ANONYMOUS) {
      setUserState(auth.state)
    }

    if (auth.user !== null) {
      fetchRemoteUser()
    } else {
      setRemoteUser({
        name: undefined,
        selfPokes: 0,
        pokes: 0,
        pokesResetTimestamp: Date.now(),
      })
    }

    const unregisterNameListener = registerNameChangeListener(() => {
      setUserState(auth.state)
      fetchRemoteUser()
    })

    return unregisterNameListener
  }, [auth.user, auth.state])

  useEffect(() => {
    if (auth.user) {
      return Database.subscribe('users/' + auth.user.uid, (data) => {
        setRemoteUser(parseRemoteUser(data))
      })
    }
  }, [auth.user, setRemoteUser])

  return (
    <CurrentUserStateContext.Provider value={stateInfo}>
      <CurrentUserContext.Provider
        value={{
          state,
          name: remoteUser.name,
          uid: auth.user?.uid,
          selfPokes: remoteUser.selfPokes,
          pokes: remoteUser.pokes,
          pokesResetTimestamp: remoteUser.pokesResetTimestamp,
        }}
      >
        {children}
      </CurrentUserContext.Provider>
    </CurrentUserStateContext.Provider>
  )
}

export function useCurrentUser() {
  const context = useContext(CurrentUserContext)

  return context
}

export function useCurrentUserState() {
  const context = useContext(CurrentUserStateContext)

  return context
}
