import './fade.css'
import React, { useState, useEffect } from 'react'

export interface FadeThroughScreenProps<T extends Record<string, React.ReactNode>> {
  currentComponent: keyof T
  components: T
  transitionTime?: number
}

export default function FadeThrough<T extends Record<string, React.ReactNode>>(
  props: FadeThroughScreenProps<T>
) {
  const [currentComponent, setCurrentComponent] = useState(props.currentComponent)
  const [previousComponent, setPreviousComponent] = useState<keyof T>()
  const [firstRender, setFirstRender] = useState(true)

  // show loading a bit longer to prevent flickering?
  // TODO: ideally we would want to skip loading screen if we have cached data
  useEffect(() => {
    setTimeout(() => {
      setFirstRender(false)
    }, 250)
  }, [])

  useEffect(() => {
    if (previousComponent || firstRender) {
      return
    }

    if (currentComponent !== props.currentComponent) {
      setPreviousComponent(currentComponent)
      setCurrentComponent(props.currentComponent)

      setTimeout(() => {
        setPreviousComponent(undefined)
      }, props.transitionTime ?? 500)
    }
  }, [props.currentComponent, previousComponent, firstRender])

  return (
    <>
      <div className="absolute-fill">{props.components[currentComponent]}</div>
      {previousComponent && (
        <div className="absolute-fill fadeOut">{props.components[previousComponent]}</div>
      )}
    </>
  )
}
