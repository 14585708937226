import useResizeObserver from "@react-hook/resize-observer"
import { useLayoutEffect, useState } from "react"

export function useSize(target: React.RefObject<HTMLElement>) {
  const [size, setSize] = useState({ width: 0, height: 0 })

  useLayoutEffect(() => {
    const size = target.current?.getBoundingClientRect()

    if (size) {
      setSize({ width: size.width, height: size.height})
    }
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize({
    width: entry.contentRect.width,
    height: entry.contentRect.height
  }))
  return size
}