import { doc, getDoc } from "firebase/firestore"
import { firestore } from "../firebase"
import { Cache } from "./cache"

function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

interface FetchConfig<T> {
  retryBackoff?: number
  maxRetries?: number
  cacheTTL?: number
  defaultValue?: T
}

export class Database {
  static clear() {
    Cache.clear()
  }

  static async fetch<T = any>(path: string, ...pathSegments: string[]): Promise<T>
  static async fetch<T = any>(config: FetchConfig<T>, path: string, ...pathSegments: string[]): Promise<T>
  static async fetch<T = any>(arg1: FetchConfig<T> | string, arg2: string, ...pathSegments: string[]): Promise<T> {
    let config: FetchConfig<T>
    let path: string

    if (typeof arg1 === 'string') {
      config = {}
      if (arg2 === undefined) {
        path = [arg1, ...pathSegments].join('/')
      } else {
        path = [arg1, arg2, ...pathSegments].join('/')
      }
    } else {
      config = arg1
      path = [arg2, ...pathSegments].join('/')
    }

    const cacheData = Cache.get<T>(path)
    if (cacheData !== undefined) {
      return cacheData
    }

    let firestoreData = (await getDoc(doc(firestore, path))).data()

    if (firestoreData === undefined && config.retryBackoff) {
      let waitTime = config.retryBackoff
      let retries = config.maxRetries ?? 5

      do {
        await sleep(waitTime)
        firestoreData = (await getDoc(doc(firestore, path))).data()

        waitTime *= 2
      } while (firestoreData === undefined && retries-- > 0)
    }
    
    // data wasn't fetched and backoff failed
    if (firestoreData === undefined) {
      if (config.defaultValue !== undefined) {
        firestoreData = config.defaultValue as any
      } else {
        throw new Error('Document at path ' + path + ' doesn\'t exist')
      }
    }

    if (config.cacheTTL !== 0) {
      // if cache ttl is undefined, the default for the path will be used, passing 0 means the cache should be bypassed
      Cache.set(path, firestoreData, config.cacheTTL)
    } else {
      Cache.invalidate(path)
    }

    this.emit(path, firestoreData)

    return firestoreData as T
  }

  static update(path: string, change: any) {
    // allow stale data, we want to perform update locally, without fetching
    const data = Cache.get(path, true)

    if (data !== undefined) {
      for (const [key, value] of Object.entries(change)) {
        if (typeof value === 'function') {
          data[key] = value(data[key])
        } else {
          data[key] = value
        }
      }

      Cache.update(path, data)
      this.emit(path, data)
    } else {
      // I don't think it's necessary to throw here
      console.warn('Couldn\'t read cache for path', path, 'while trying to update')
    }
  }

  private static emit(path: string, data: any) {
    const event = new CustomEvent(path, { detail: data })
    window.dispatchEvent(event)
  }

  static subscribe<T = any>(path: string, handler: (newData: T) => void): () => void {
    const eventListener = ((e: CustomEvent) => {
      handler(e.detail)
    }) as EventListener;
  
    window.addEventListener(path, eventListener)
  
    return () => {
      window.removeEventListener(path, eventListener)
    }
  }
}
