import './firebase'
import './i18n'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import './index.css'
import HomePage from './pages/home/HomePage'
import UserPage from './pages/user/UserPage'
import ErrorPage from './pages/error/ErrorPage'
import RankingPage from './pages/ranking/RankingPage'
import CustomizePage from './pages/customize/CustomizePage'
import { AuthInfoProvider } from './auth/AuthInfoProvider'
import { CurrentUserProvider } from './user/CurrentUserProvider'
import { getCurrentSeasonId } from 'functions/lib/common'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'u/:name',
    element: <UserPage />,
  },
  {
    path: 'customize',
    element: <CustomizePage />,
  },
  {
    path: 'ranking',
    element: <Navigate to={'/ranking/' + getCurrentSeasonId()} replace />,
  },
  {
    path: 'ranking/:season',
    element: <RankingPage />,
  },
])

root.render(
  <React.StrictMode>
    <AuthInfoProvider>
      <CurrentUserProvider>
        <RouterProvider router={router} />
      </CurrentUserProvider>
    </AuthInfoProvider>
  </React.StrictMode>
)
