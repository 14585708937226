import { doc, getDoc, runTransaction } from "firebase/firestore"
import { firestore } from "../firebase"
import { EventHelper} from "../eventHelper";
import { TranslationKeys } from "../i18n";

export function registerNameChangeListener(fn: (name: string) => void) {
  return EventHelper.addListener('onUserNameChange', fn)
}

export async function setUserName(uid: string, name: string): Promise<string> {
  name = name.trim().toLowerCase()
  
  if (!name.match(/^[a-z0-9_]+$/)) {
    throw new Error(TranslationKeys.errorUsernameInvalidCharacters)
  }

  if (name.length < 3) {
    throw new Error(TranslationKeys.errorUsernameTooShort)
  }

  if (name.length > 20) {
    throw new Error(TranslationKeys.errorUsernameTooLong)
  }

  try {
    await runTransaction(firestore, async (transaction) => {
      const nameDocRef = doc(firestore, 'name2uid', name)
      const nameDoc = await getDoc(nameDocRef)

      if (nameDoc.exists()) {
        throw {
          passToUser: true,
          message: TranslationKeys.errorNameTaken,
        }
      }

      transaction.set(doc(firestore, 'uid2name', uid), { name })
      transaction.set(nameDocRef, { uid })
    })

    EventHelper.dispatch('onUserNameChange', name)
    return name
  } catch (e: any) {
    if (e.passToUser) {
      throw new Error(e.message)
    } else {
      throw new Error(TranslationKeys.errorUnknown)
    }
  }
}