export class EventHelper {
  static addListener<T = any>(name: string, handler: (data: T) => void) {
    const eventListener = ((e: CustomEvent) => {
      handler(e.detail)
    }) as EventListener;
  
    window.addEventListener(name, eventListener)
  
    return () => {
      window.removeEventListener(name, eventListener)
    }
  }
  
  static dispatch<T = any>(name: string, data: T) {
    const event = new CustomEvent(name, { detail: data })
    window.dispatchEvent(event)
  }
}
