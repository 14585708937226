import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { UserState, useCurrentUser } from '../../user/CurrentUserProvider'
import { Database } from '../../database/database'
import FullscreenLoading from '../../components/FullscreenLoading'
import FadeThrough from '../../components/FadeThrough'
import { useTranslation } from 'react-i18next'
import TopBar from '../../components/TopBar'
import { TranslationKeys } from '../../i18n'
import { Knaga } from '../../components/Knaga'
import { TipsModal } from './TipsModal'
import { useAnalyticsEvent } from '../../hooks/useAnalyticsEvent'
import ErrorPage from '../error/ErrorPage'

export default function UserPage() {
  const [uid, setUid] = useState<string>()
  const [error, setError] = useState<(typeof TranslationKeys)[keyof typeof TranslationKeys]>()
  const user = useCurrentUser()
  const params = useParams()
  const translation = useTranslation()
  const navigation = useNavigate()
  useAnalyticsEvent(
    { viewingSelf: user.uid === uid },
    user.state !== UserState.UNKNOWN && uid !== undefined
  )

  useEffect(() => {
    if (params.name) {
      Database.fetch('name2uid', params.name)
        .then(({ uid }) => setUid(uid))
        .catch(() => {
          setError('error_user_does_not_exist')
        })
    }
  }, [params.name])

  const screens = {
    loading: <FullscreenLoading />,
    error: <ErrorPage errorKey={error} />,
    knaga: (
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: '100%',
          flexDirection: 'column',
          paddingBottom: 24,
          boxSizing: 'border-box',
        }}
      >
        <TopBar />

        <div style={{ textAlign: 'center', fontWeight: 500, fontSize: '1.5em' }}>
          {user.uid === uid ? (
            <>
              <div style={{ position: 'absolute', right: 16, fontSize: '0.7em' }}>
                <button
                  onClick={() => {
                    navigation('/customize')
                  }}
                >
                  Edytuj
                </button>
              </div>
              {translation.t(TranslationKeys.yourKnaga)}
            </>
          ) : (
            translation.t(TranslationKeys.someonesKnaga, { name: params.name })
          )}
        </div>
        <Knaga uid={uid} />
        <TipsModal />
      </div>
    ),
  }

  if (user.state === UserState.UNNAMED) {
    return <Navigate to="/" />
  }

  const currentScreen =
    error !== undefined ? 'error' : uid && user.state !== UserState.UNKNOWN ? 'knaga' : 'loading'

  return <FadeThrough currentComponent={currentScreen} components={screens} transitionTime={250} />
}
