import { useTranslation } from 'react-i18next'
import { signIn } from '../../auth/authentication'
import { TranslationKeys } from '../../i18n'
import { useAnalyticsEvent } from '../../hooks/useAnalyticsEvent'

export default function LandingScreen() {
  useAnalyticsEvent({ screen: 'landing' })
  const translation = useTranslation()

  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <span style={{ textAlign: 'center', fontSize: '1.5em', margin: 16 }}>
        Kiedyś będzie zajebisty landing, teraz jest guzik:
      </span>
      <div style={{ textAlign: 'center' }}>
        <button
          onClick={() => {
            signIn()
          }}
        >
          {translation.t(TranslationKeys.signIn)}
        </button>
      </div>
    </div>
  )
}
