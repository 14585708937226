import { useTranslation } from 'react-i18next'
import ActivityIndicator from './ActivityIndicator'
import { TranslationKeys } from '../i18n'

export default function FullscreenLoading() {
  const translation = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'black',
      }}
    >
      <span
        style={{
          fontSize: '3em',
          fontWeight: '600',
          marginBottom: '8px',
        }}
      >
        {translation.t(TranslationKeys.appName)}
      </span>
      <ActivityIndicator />
    </div>
  )
}
