import React, { useState, useEffect, useContext } from 'react'
import { User, getAuth } from 'firebase/auth'

export enum AuthState {
  UNKNOWN,
  SIGNED_IN,
  ANONYMOUS,
  // not really auth state but makes typing easy across auth & user
  UNNAMED,
}

export interface AuthInfo {
  state: AuthState
  user: null | User
}

const AuthContext = React.createContext<AuthInfo>({
  state: AuthState.UNKNOWN,
  user: null,
})

export function AuthInfoProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthInfo>({
    state: AuthState.UNKNOWN,
    user: null,
  })

  useEffect(() => {
    const unregisterAuthObserver = getAuth().onAuthStateChanged((user) => {
      setState({ user, state: user === null ? AuthState.ANONYMOUS : AuthState.SIGNED_IN })
    })
    return () => unregisterAuthObserver()
  }, [])

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
