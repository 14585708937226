import { KnagaInfo } from "../../hooks/useKnaga";
import { getBallsSize } from "./sizing";
import { Animation, Dimensions } from "./types";

export function drawBalls(
  context: CanvasRenderingContext2D,
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
  const scale = window.devicePixelRatio
  const size = getBallsSize(canvasSize, animation, knaga)

  if (knaga.balls === 1) {
    const radius = size / 2

    context.beginPath()
    context.arc(
      ((canvasSize.width - knaga.girth) / 2 - 8) * scale,
      (canvasSize.height - radius) * scale,
      radius * scale,
      0,
      2 * Math.PI
    )
    context.fill()

    context.beginPath()
    context.arc(
      ((canvasSize.width + knaga.girth) / 2 + 8) * scale,
      (canvasSize.height - radius) * scale,
      radius * scale,
      0,
      2 * Math.PI
    )
    context.fill()
  } else if (knaga.balls === 2) {
    context.beginPath()
    context.moveTo((canvasSize.width - knaga.girth) / 2 * scale + 1, (canvasSize.height - 16) * scale)
    context.lineTo((canvasSize.width - knaga.girth) / 2 * scale + 1, (canvasSize.height - size) * scale)
    context.lineTo((canvasSize.width - knaga.girth - 64) / 2 * scale, (canvasSize.height - size + 32) * scale)
    context.lineTo((canvasSize.width - knaga.girth - 64) / 2 * scale, (canvasSize.height - 0) * scale)
    context.fill()

    context.beginPath()
    context.moveTo((canvasSize.width + knaga.girth) / 2 * scale - 1, (canvasSize.height - 16) * scale)
    context.lineTo((canvasSize.width + knaga.girth) / 2 * scale - 1, (canvasSize.height - size) * scale)
    context.lineTo((canvasSize.width + knaga.girth + 64) / 2 * scale, (canvasSize.height - size + 32) * scale)
    context.lineTo((canvasSize.width + knaga.girth + 64) / 2 * scale, (canvasSize.height - 0) * scale)
    context.fill()
  }
}