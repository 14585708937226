export const Consts = {
  DefaultSelfPokes: 10,
  DefaultPokes: 5,
  DefaultBalls: 1,
  DefaultTip: 1,
  DefaultDecoration: 0,
  MinimalGirth: 48,
  MaximalGirth: 96,
  DefaultGirth: 64,
  DefaultSize: 5,
  DefaultFill: ['#ffffff'],

  createDefaultKnaga() {
    return {
      balls: Consts.DefaultBalls,
      decoration: Consts.DefaultDecoration,
      fill: Consts.DefaultFill,
      girth: Consts.DefaultGirth,
      size: Consts.DefaultSize,
      tip: Consts.DefaultTip,
    }
  },
}

export function getSeasonId(date: Date) {
  return 'current'
}

export function getCurrentSeasonId() {
  return getSeasonId(new Date())
}

export function getKnagaPath(uid: string, date: Date) {
  return `seasons/${getSeasonId(date)}/users/${uid}`
}

export function getCurrentKnagaPath(uid: string) {
  return getKnagaPath(uid, new Date())
}
