import './index.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TopBar from '../../components/TopBar'
import { TranslationKeys } from '../../i18n'
import { UserState, useCurrentUser } from '../../user/CurrentUserProvider'
import FullscreenLoading from '../../components/FullscreenLoading'
import FadeThrough from '../../components/FadeThrough'
import { KnagaInfo, useKnaga } from '../../hooks/useKnaga'
import { Consts, getCurrentKnagaPath } from 'functions/lib/common'
import { KnagaCanvas } from '../../components/Knaga/KnagaCanvas'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { firestore } from '../../firebase'
import ActivityIndicator from '../../components/ActivityIndicator'
import { Database } from '../../database/database'

export default function CustomizePage() {
  const user = useCurrentUser()
  const translation = useTranslation()
  const [maxKnagaSize, setMaxKnagaSize] = useState(0)
  const [waiting, setWaiting] = useState(false)
  const userKnaga = useKnaga(user.uid)
  const [knaga, setKnaga] = useState<KnagaInfo>({
    ...Consts.createDefaultKnaga(),
    name: '',
    uid: '',
  })

  useEffect(() => {
    if (userKnaga) {
      setKnaga({ ...userKnaga })
    }
  }, [userKnaga?.name])

  const screens = {
    loading: <FullscreenLoading />,
    customize: (
      <div className="customize-page">
        <TopBar />
        <div className="customize-container">
          <div className="preview-container">
            <div
              style={{
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 24,
                paddingRight: 24,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 16,
              }}
            >
              <div style={{ maxWidth: '40%' }}>
                {translation.t(TranslationKeys.customizeSizeHint)}
              </div>
              <div style={{ flex: 1 }}>
                <input
                  style={{ width: '90%' }}
                  type="range"
                  min={5}
                  max={maxKnagaSize}
                  value={knaga.size}
                  onChange={(e) => {
                    setKnaga({ ...knaga, size: parseInt(e.target.value) })
                  }}
                />
              </div>
            </div>
            <div
              ref={(ref) => {
                if (ref) {
                  setMaxKnagaSize(ref.getBoundingClientRect().height)
                }
              }}
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
              }}
            >
              <KnagaCanvas knaga={knaga} />
            </div>
          </div>
          <div className="options-container">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 16 }}>
              {translation.t(TranslationKeys.customizeGirthHint)}
              <input
                style={{ flexGrow: 1 }}
                type="range"
                min={48}
                max={96}
                value={knaga.girth}
                onChange={(e) => {
                  setKnaga({ ...knaga, girth: parseInt(e.target.value) })
                }}
              />
            </div>

            <select
              value={knaga.tip}
              onChange={(e) => {
                setKnaga({ ...knaga, tip: parseInt(e.target.value) })
              }}
            >
              <option value="0">{translation.t(TranslationKeys.customizeTipFlat)}</option>
              <option value="1">{translation.t(TranslationKeys.customizeTipRound)}</option>
              <option value="2">{translation.t(TranslationKeys.customizeTipRocket)}</option>
              <option value="3">{translation.t(TranslationKeys.customizeTipAntennae)}</option>
              <option value="4">{translation.t(TranslationKeys.customizeTipFish)}</option>
            </select>

            <select
              value={knaga.decoration}
              onChange={(e) => {
                setKnaga({ ...knaga, decoration: parseInt(e.target.value) })
              }}
            >
              <option value="0">{translation.t(TranslationKeys.customizeDecorationNone)}</option>
              <option value="1">{translation.t(TranslationKeys.customizeDecorationName)}</option>
              <option value="2">
                {translation.t(TranslationKeys.customizeDecorationCentipede)}
              </option>
            </select>

            <select
              value={knaga.balls}
              onChange={(e) => {
                setKnaga({ ...knaga, balls: parseInt(e.target.value) })
              }}
            >
              <option value="0">{translation.t(TranslationKeys.customizeBallsNone)}</option>
              <option value="1">{translation.t(TranslationKeys.customizeBallsBalls)}</option>
              <option value="2">{translation.t(TranslationKeys.customizeBallsBoosters)}</option>
            </select>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 16 }}>
              Kolor:
              <input
                style={{ flexGrow: 1, minHeight: 48 }}
                type="color"
                value={knaga.fill[0]}
                onChange={(e) => {
                  setKnaga({ ...knaga, fill: [e.target.value] })
                }}
              />
            </div>

            <div style={{ textAlign: 'center', marginTop: 16 }}>
              <div>
                <button
                  disabled={waiting || user.state === UserState.ANONYMOUS}
                  onClick={() => {
                    // TODO: validate on db side?
                    // TODO: this can be done better
                    setWaiting(true)
                    updateDoc(doc(firestore, getCurrentKnagaPath(user.uid!)), {
                      girth: knaga.girth,
                      tip: knaga.tip,
                      decoration: knaga.decoration,
                      balls: knaga.balls,
                      fill: knaga.fill,
                    })
                      .then(() => {
                        Database.update(getCurrentKnagaPath(user.uid!), {
                          girth: knaga.girth,
                          tip: knaga.tip,
                          decoration: knaga.decoration,
                          balls: knaga.balls,
                          fill: knaga.fill,
                        })

                        setWaiting(false)
                      })
                      .catch(() => {
                        setDoc(doc(firestore, getCurrentKnagaPath(user.uid!)), {
                          ...Consts.createDefaultKnaga(),
                          girth: knaga.girth,
                          tip: knaga.tip,
                          decoration: knaga.decoration,
                          balls: knaga.balls,
                          fill: knaga.fill,
                        })
                          .then(() => {
                            Database.update(getCurrentKnagaPath(user.uid!), {
                              girth: knaga.girth,
                              tip: knaga.tip,
                              decoration: knaga.decoration,
                              balls: knaga.balls,
                              fill: knaga.fill,
                            })

                            setWaiting(false)
                          })
                          .catch(() => {
                            setWaiting(false)
                            alert(translation.t(TranslationKeys.errorUnknown))
                          })
                      })
                  }}
                >
                  {translation.t(TranslationKeys.customizeSave)}
                </button>
              </div>

              <div
                style={{
                  marginTop: 16,
                  display: 'inline-block',
                  visibility: waiting ? 'visible' : 'hidden',
                }}
              >
                <ActivityIndicator />
              </div>
            </div>

            <div className="customization-warning">
              {translation.t(TranslationKeys.customizeWarning)}
            </div>
          </div>
        </div>
      </div>
    ),
  }

  const current = user.state === UserState.UNKNOWN ? 'loading' : 'customize'

  return <FadeThrough components={screens} currentComponent={current} />
}
