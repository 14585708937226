import { useCurrentUser, useCurrentUserState, UserState } from '../../user/CurrentUserProvider'
import FadeThrough from '../../components/FadeThrough'
import FullscreenLoading from '../../components/FullscreenLoading'
import SetUsernameScreen from './SetUsernameScreen'
import LandingScreen from './LandingScreen'
import { Navigate } from 'react-router-dom'

function UserScreen() {
  const user = useCurrentUser()

  return <Navigate to={`/u/${user.name}`} replace />
}

export default function Home() {
  const user = useCurrentUserState()

  const screens = {
    loading: <FullscreenLoading />,
    landing: <LandingScreen />,
    unnamed: <SetUsernameScreen />,
    user: <UserScreen />,
  }

  let currentScreen: keyof typeof screens = 'loading'

  switch (user.state) {
    case UserState.SIGNED_IN:
      currentScreen = 'user'
      break
    case UserState.UNNAMED:
      currentScreen = 'unnamed'
      break
    case UserState.ANONYMOUS:
      currentScreen = 'landing'
      break
  }

  return <FadeThrough components={screens} currentComponent={currentScreen} />
}
