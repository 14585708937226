import { useTranslation } from 'react-i18next'
import TopBar from '../../components/TopBar'
import { TranslationKeys } from '../../i18n'
import { UserState, useCurrentUserState } from '../../user/CurrentUserProvider'
import FullscreenLoading from '../../components/FullscreenLoading'
import FadeThrough from '../../components/FadeThrough'

export interface ErrorPageProps {
  errorKey?: (typeof TranslationKeys)[keyof typeof TranslationKeys]
}

export default function ErrorPage(props: ErrorPageProps) {
  const { errorKey = TranslationKeys.errorPageDoesNotExist } = props

  const user = useCurrentUserState()
  const translation = useTranslation()

  const screens = {
    loading: <FullscreenLoading />,
    error: (
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TopBar />
        <div style={{ margin: 64, fontSize: '1.5em' }}>{translation.t(errorKey)}</div>
      </div>
    ),
  }

  const current = user.state === UserState.UNKNOWN ? 'loading' : 'error'

  return <FadeThrough components={screens} currentComponent={current} />
}
