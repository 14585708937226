import { useState, useEffect } from 'react'
import { Database } from '../database/database'
import { Consts, getCurrentKnagaPath } from 'functions/lib/common'
import { TranslationKeys } from '../i18n'

interface RemoteKnagaInfo {
  balls: number
  decoration: number
  fill: string[]
  girth: number
  size: number
  tip: number
}

export interface KnagaInfo extends RemoteKnagaInfo {
  name: string
  uid: string
}

export function useKnaga(uid?: string) {
  const [knaga, setKnaga] = useState<KnagaInfo | undefined>()
  const [name, setName] = useState<string | undefined>()

  useEffect(() => {
    if (uid !== undefined) {
      Database.fetch('uid2name', uid).then((result) => {
        setName(result.name)

        Database.fetch<RemoteKnagaInfo>({ defaultValue: Consts.createDefaultKnaga() }, getCurrentKnagaPath(uid)).then((result) => {
          setKnaga({
            name: '',
            uid: uid,
            ...result,
          })
        })
      }).catch(() => {
        throw new Error(TranslationKeys.errorPageDoesNotExist)
      })
    }
  }, [uid])

  useEffect(() => {
    return Database.subscribe<RemoteKnagaInfo>(getCurrentKnagaPath(uid!), (data) => {
      if (uid) {
        setKnaga({
          name: '',
          uid,
          ...data,
        })
      }
    })
  }, [uid])

  // TODO: don't return different object on every render
  return knaga && name ? {
    ...knaga,
    name
  } : undefined
}