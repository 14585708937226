import './style.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TopBar from '../../components/TopBar'
import { TranslationKeys } from '../../i18n'
import { UserState, useCurrentUserState } from '../../user/CurrentUserProvider'
import FullscreenLoading from '../../components/FullscreenLoading'
import FadeThrough from '../../components/FadeThrough'
import { Link, useParams } from 'react-router-dom'
import ErrorPage from '../error/ErrorPage'
import { Database } from '../../database/database'

interface Entry {
  uid: string
  name: string
  size: number
}

export default function RankingPage() {
  const [ranking, setRanking] = useState<Entry[]>()
  const [error, setError] = useState<(typeof TranslationKeys)[keyof typeof TranslationKeys]>()
  const { season } = useParams()
  const user = useCurrentUserState()
  const translation = useTranslation()

  useEffect(() => {
    if (season) {
      Database.fetch('seasons', season, 'rankings', 'size')
        .then((data) => {
          setRanking(data.ranking)
        })
        .catch(() => {
          setError(TranslationKeys.errorRankingDoesNotExist)
        })
    }
  }, [season])

  const screens = {
    loading: <FullscreenLoading />,
    error: <ErrorPage errorKey={error} />,
    ranking: (
      <div style={{ textAlign: 'center' }}>
        <TopBar />
        <div className="ranking-header">{translation.t(TranslationKeys.theyHaveLarestKnagas)}</div>

        <div className="ranking-container">
          <ol>
            {ranking?.map((entry) => (
              <Link to={`/u/${entry.name}`} key={entry.uid}>
                <li>
                  <div className="entry">
                    <span>{entry.name}</span>
                    <span>{translation.t(TranslationKeys.knagaSize, { size: entry.size })}</span>
                  </div>
                </li>
              </Link>
            ))}
          </ol>
        </div>
      </div>
    ),
  }

  const current =
    error !== undefined
      ? 'error'
      : user.state === UserState.UNKNOWN || ranking === undefined
      ? 'loading'
      : 'ranking'

  return <FadeThrough components={screens} currentComponent={current} />
}
