import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  pl: {
    translation: {
      app_name: 'Knaga',
      ok: 'Ok',
      sign_in: 'Zaloguj',
      sign_out: 'Wyloguj',
      poke_knaga: 'Szturchnij Knagę',
      knaga_size: 'Rozmiar Knagi: {{size}}',
      your_knaga: 'Twoja knaga',
      someones_knaga: 'Knaga {{name}}',
      select_username: 'Wybierz nazwę użytkownika',
      username_placeholder: 'Nazwa użytkownika',
      pokes_left_zero: 'Nie możesz już szturchać innych.',
      pokes_left_one: 'Możesz szturchnąć kogoś innego jeszcze raz.',
      pokes_left_few: 'Możesz szturchnąć kogoś innego jeszcze {{count}} razy.',
      pokes_left_many: 'Możesz szturchnąć kogoś innego jeszcze {{count}} razy.',
      self_pokes_left_zero: 'Nie możesz się już szturchać.',
      self_pokes_left_one: 'Możesz szturchnąć się jeszcze raz.',
      self_pokes_left_few: 'Możesz szturchnąć się jeszcze {{count}} razy.',
      self_pokes_left_many: 'Możesz szturchnąć się jeszcze {{count}} razy.',
      come_back_in_hours_one: 'Wróć za godzinę.',
      come_back_in_hours_few: 'Wróć za {{count}} godziny.',
      come_back_in_hours_many: 'Wróć za {{count}} godzin.',
      important_information: 'Ważna informacja',
      easy_steps_to_enlarge_knaga: 'Oto 3 proste sposoby na powiększenie Knagi:',
      enlarge_knaga_step_1: 'Szturchnij Knagę. Szturchanie Knagi zwiększa jej rozmiar.',
      enlarge_knaga_step_2: 'Poproś znajomych o szturchanie Twojej Knagi. Szturchanie Knagi przez innych zwiększa jej rozmiar jeszcze bardziej.',
      enlarge_knaga_step_3: 'Pamiętaj, że Ty też możesz szturchać knagi innym. Szturchanie Knagi innych zwiększa też Twój rozmiar.',
      ranking_button: 'Ranking',
      they_have_larest_knagas: 'Oni mają największe Knagi:',
      error_unknown_login_provider: 'Wystąpił błąd: nieznany dostawca logowania',
      error_username_too_short: 'Nazwa użytkownika musi mieć co najmniej 3 znaki',
      error_username_too_long: 'Nazwa użytkownika może mieć makzymalnie 20 znaków',
      error_username_invalid_characters: 'Nazwa użytkownika może zawierać tylko litery, cyfry i podkreślenia',
      error_unknown: 'Wystąpił nieoczekiwany błąd',
      error_name_taken: 'Ta nazwa jest już zajęta',
      error_page_does_not_exist: 'Ta strona nie istnieje :\'(',
      error_user_does_not_exist: 'Nie ma takiego użytkownika :\'(',
      error_ranking_does_not_exist: 'Nie ma takiego rankingu :\'(',

      customize_size_hint: 'Rozmiar (tylko do podglądu):',
      customize_girth_hint: 'Szerokość:',
      customize_save: 'Zapisz',
      customize_warning: 'Niektóre opcje mogą nie być widoczne jeżeli Twoja Knaga jest za mała.',
      customize_tip_flat: 'Płaski',
      customize_tip_round: 'Zaokrąglony',
      customize_tip_rocket: 'Rakieta',
      customize_tip_antennae: 'Czułki',
      customize_tip_fish: 'Ryba',
      customize_decoration_none: 'Brak dekoracji',
      customize_decoration_name: 'Nazwa',
      customize_decoration_centipede: 'Stonoga',
      customize_balls_none: 'Brak podstawy',
      customize_balls_balls: 'Kule',
      customize_balls_boosters: 'Boostery',
    },
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['pl'],
    fallbackLng: 'pl',
    debug: true,
  });

export default i18next;

export const TranslationKeys = {
  appName: 'app_name',
  ok: 'ok',
  signIn: 'sign_in',
  signOut: 'sign_out',
  pokeKnaga: 'poke_knaga',
  knagaSize: 'knaga_size',
  yourKnaga: 'your_knaga',
  someonesKnaga: 'someones_knaga',
  selectUsername: 'select_username',
  usernamePlaceholder: 'username_placeholder',
  pokesLeft: 'pokes_left',
  selfPokesLeft: 'self_pokes_left',
  comeBackInHours: 'come_back_in_hours',
  importantInformation: 'important_information',
  easyStepsToEnlargeKnaga: 'easy_steps_to_enlarge_knaga',
  enlargeKnagaStep1: 'enlarge_knaga_step_1',
  enlargeKnagaStep2: 'enlarge_knaga_step_2',
  enlargeKnagaStep3: 'enlarge_knaga_step_3',
  rankingButton: 'ranking_button',
  theyHaveLarestKnagas: 'they_have_larest_knagas',
  errorUsernameTooShort: 'error_username_too_short',
  errorUsernameTooLong: 'error_username_too_long',
  errorUsernameInvalidCharacters: 'error_username_invalid_characters',
  errorUnknownLoginProvider: 'error_unknown_login_provider',
  errorUnknown: 'error_unknown',
  errorNameTaken: 'error_name_taken',
  errorPageDoesNotExist: 'error_page_does_not_exist',
  errorUserDoesNotExist: 'error_user_does_not_exist',
  errorRankingDoesNotExist: 'error_ranking_does_not_exist',

  customizeSizeHint: 'customize_size_hint',
  customizeGirthHint: 'customize_girth_hint',
  customizeSave: 'customize_save',
  customizeWarning: 'customize_warning',
  customizeTipFlat: 'customize_tip_flat',
  customizeTipRound: 'customize_tip_round',
  customizeTipRocket: 'customize_tip_rocket',
  customizeTipAntennae: 'customize_tip_antennae',
  customizeTipFish: 'customize_tip_fish',
  customizeDecorationNone: 'customize_decoration_none',
  customizeDecorationName: 'customize_decoration_name',
  customizeDecorationCentipede: 'customize_decoration_centipede',
  customizeBallsNone: 'customize_balls_none',
  customizeBallsBalls: 'customize_balls_balls',
  customizeBallsBoosters: 'customize_balls_boosters',
};