import { KnagaInfo } from "../../hooks/useKnaga";
import { getFillStyle, isDark } from "./color";
import { KNAGA_BOTTOM_MARGIN, getBallsSize, getClampedKnagaHeight, getTipSize } from "./sizing";
import { Animation, Dimensions } from "./types";

function measureNameDecoration(context: CanvasRenderingContext2D, name: string) {
  let margin = 0;

  for (let i = 0; i < name.length; i++) {
    const char = name[i];
    const metrics = context.measureText(char);

    margin += (metrics.actualBoundingBoxDescent + metrics.actualBoundingBoxAscent) * 1.1;
  }

  return margin;
}

function matchFontToSize(context: CanvasRenderingContext2D, knaga: KnagaInfo, knagaSize: number) {
  const scale = window.devicePixelRatio
  context.font = 'bold ' + knaga.girth + 'px Quicksand';

  let fontSize = knaga.girth;
  let textHeight = measureNameDecoration(context, knaga.name);

  while (textHeight > knagaSize * 0.6 && fontSize > 0) {
    fontSize -= 1;
    context.font = 'bold ' + fontSize + 'px Quicksand';
    textHeight = measureNameDecoration(context, knaga.name);
  }

  context.font = 'bold ' + (fontSize * scale) + 'px Quicksand';
}

export function drawDecoration(
  context: CanvasRenderingContext2D,
  canvasSize: Dimensions,
  animation: Animation,
  knaga: KnagaInfo
) {
  const scale = window.devicePixelRatio
  const knagaSize = getClampedKnagaHeight(canvasSize, animation, knaga)
  const ballsSize = getBallsSize(canvasSize, animation, knaga)
  const tipSize = getTipSize(canvasSize, animation, knaga)

  if (knaga.decoration === 1) {
    context.textBaseline = 'top'
    matchFontToSize(context, knaga, knagaSize)
    context.fillStyle = isDark(knaga.fill[0]) ? 'white' : 'black';
    context.textAlign = 'center';

    const startY = ((canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN - 1) * scale + (knagaSize * scale - measureNameDecoration(context, knaga.name)) / 2.25)
    let margin = 0;

    for (let i = 0; i < knaga.name.length; i++) {
      const char = knaga.name[i];
      const metrics = context.measureText(char);

      context.fillText(char, canvasSize.width / 2 * scale, startY + margin);

      margin += (metrics.actualBoundingBoxDescent + metrics.actualBoundingBoxAscent) * 1.1;
    }

    context.fillStyle = getFillStyle(knaga)
  } else if (knaga.decoration === 2) {
    context.fillStyle = 'red';
    const marginY = (canvasSize.height - knagaSize - KNAGA_BOTTOM_MARGIN + tipSize) * scale
    const availableHeight = (knagaSize - ballsSize - tipSize) * scale

    context.fillStyle = 'white';

    context.lineWidth = 6 * scale

    let margin = marginY + availableHeight;
    if (margin > (canvasSize.height - KNAGA_BOTTOM_MARGIN - knaga.girth / 2) * scale) {
      margin = (canvasSize.height - KNAGA_BOTTOM_MARGIN - knaga.girth / 2) * scale
    }

    while (margin > marginY) {
      context.beginPath()
      context.moveTo((canvasSize.width - knaga.girth + 2) / 2 * scale, margin)
      context.lineTo((canvasSize.width - knaga.girth - 50) / 2 * scale, margin - 8 * scale)
      context.lineTo((canvasSize.width - knaga.girth - 90) / 2 * scale, margin + 10 * scale)
      context.stroke()

      context.beginPath()
      context.moveTo((canvasSize.width + knaga.girth - 2) / 2 * scale, margin)
      context.lineTo((canvasSize.width + knaga.girth + 50) / 2 * scale, margin - 8 * scale)
      context.lineTo((canvasSize.width + knaga.girth + 90) / 2 * scale, margin + 10 * scale)
      context.stroke()
      
      margin -= 25 * scale
    }
  }
}