import { useTranslation } from 'react-i18next'
import { signIn, signOut } from '../auth/authentication'
import { UserState, useCurrentUserState } from '../user/CurrentUserProvider'
import { Link, useNavigate } from 'react-router-dom'
import { TranslationKeys } from '../i18n'

export default function TopBar() {
  const translation = useTranslation()
  const user = useCurrentUserState()
  const navigate = useNavigate()

  return (
    <div
      style={{
        width: '100vw',
        height: 64,
        backgroundColor: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
        boxSizing: 'border-box',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#ffffff55',
        marginBottom: 16,
      }}
    >
      <Link to="/">
        <div
          style={{ fontSize: '1.6em', color: '#ffffff', fontWeight: 600 }}
          className="shake-on-hover"
        >
          {translation.t(TranslationKeys.appName)}
        </div>
      </Link>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <button
          onClick={() => {
            navigate('/ranking')
          }}
        >
          {translation.t(TranslationKeys.rankingButton)}
        </button>

        {(user.state === UserState.SIGNED_IN || user.state === UserState.UNNAMED) && (
          <button
            onClick={() => {
              signOut()
            }}
          >
            {translation.t(TranslationKeys.signOut)}
          </button>
        )}

        {user.state === UserState.ANONYMOUS && (
          <button
            onClick={() => {
              signIn()
            }}
          >
            {translation.t(TranslationKeys.signIn)}
          </button>
        )}
      </div>
    </div>
  )
}
